import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import FormResume from '../../../components/formResume'
import { MDBAnimation } from 'mdbreact'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Submit your resume"
            description="Submit your resume to apply for any of our open job vacancies"
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}            
          />
          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.fluid}
            foreimage={null}
            imageAltText={post.frontmatter.alttext}
            title="Submit your resume"
            subtitle="Careers at UNICOM Engineering"
            type="root"
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <FormResume />
            </MDBAnimation>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "contact" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
      }
    }
  }
`