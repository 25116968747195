import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact'
import axios from 'axios'
import { Link } from 'gatsby'

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  surname: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  street: Yup.string()
    .min(5, 'Street must be 5 characters at minimum')
    .required('Street is required'),
  city: Yup.string()
    .min(2, 'City must be 2 characters at minimum')
    .required('City is required'),
  state: Yup.string()
    .min(2, 'State must be 2 characters at minimum')
    .required('State is required'),
  zip: Yup.string()
    .min(5, 'Zip/postcode must be 5 characters at minimum')
    .required('Zip/postcode is required'),
  country: Yup.string()
    .min(2, 'Country must be 2 characters at minimum')
    .required('Country is required'),
  mainphone: Yup.string()
    .min(10, 'Main phone number must be 10 characters at minimum')
    .required('Main phone number is required'),
  comments: Yup.string()
    .min(10, 'Explanation must be 10 characters at minimum')
    .required('How you heard about us is required'),
})

const url = 'https://script.google.com/macros/s/AKfycbxc59wLPEQbyJz_GERg3EQwrTbngCBT2diL-tIqFUqjyxkKZuGmWvDKv7fXTPNFLr5sBg/exec'

class formResume extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null, 
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true, 
        pageUrl: location.href 
      })
    })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow >
          <MDBCol md="2" ></MDBCol>
          <MDBCol md="8" className="md-0 mb-5">
            <Formik
              initialValues={{
                firstname: '',
                middlename: '',
                surname: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
                mainphone: '',
                homephone: '',
                mobilephone: '',
                workphone: '',
                linkedin: '',
                comments: '',
                resume: '', 
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                    Firstname: values.firstname,
                    Middlename: values.middlename,
                    Surname: values.surname,
                    Street: values.street,
                    City: values.city,
                    State: values.state,
                    Zip: values.zip,
                    Country: values.country,
                    Email: values.email,
                    Mainphone: values.mainphone,
                    Homephone: values.homephone,
                    Mobilephone: values.mobilephone,
                    Workphone: values.workphone,
                    LinkedIn: values.linkedin, 
                    Comments: values.comments,
                    Resume: values.resume, 
                    Subscribe: values.subscribe,
                }

                let fileData = new FormData() 
                fileData.append("file", this.state.selectedFile)

                // upload resume
                const file = this.state.selectedFile
                //console.log(file)
                const fr = new FileReader()
                fr.readAsArrayBuffer(file)
                fr.onload = f => { 
                  const qs = new URLSearchParams({timestamp: "", firstname: values.firstname, middlename: values.middlename, surname: values.surname, street: values.street, city: values.city, state: values.state, zip: values.zip, country: values.country, email: values.email, mainphone: values.mainphone, homephone: values.homephone, mobilephone: values.mobilephone, workphone: values.workphone, linkedin: values.linkedin, comments: values.comments, vacancy: location.href, resume: "", filename: this.state.selectedFile.name, mimeType: this.state.selectedFile.type});
                  //console.log(`${url}?${qs}`)     
                  //console.log(f.target.result)         
                    
                    fetch(`${url}?${qs}`, {method: "POST", mode: "cors", body: JSON.stringify([...new Int8Array(f.target.result)])})
                    .then(res => res.json())
                    .then(e => {
                      //console.log(encodeURIComponent(e))
                      this.setState({ values: "", expired: "true" })
                      resetForm()
                      setSubmitting(false)
                    })
                    .catch(err => {
                      console.log(err)
                      setSubmitting(false)
                    })
                }
                //setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="firstname">
                          First name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="firstname"
                          name="firstname"
                          value={values.firstname}
                          placeholder="Your first name"
                          className={`form-control ${
                            touched.firstname && errors.firstname ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="firstname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="middlename">
                          Middle name 
                        </label>
                        <Field
                          type="middlename"
                          name="middlename"
                          value={values.middlename}
                          placeholder="Your middle name"
                          className={`form-control ${
                            touched.middlename && errors.middlename ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="middlename"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="4">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="surname">
                          Surname <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="surname"
                          name="surname"
                          value={values.surname}
                          placeholder="Your surname"
                          className={`form-control ${
                            touched.surname && errors.surname ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="surname"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="street">
                          Street <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="street"
                          value={values.street}
                          placeholder="Enter street"
                          className={`form-control ${
                            touched.street && errors.street
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="street"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="city">
                          City <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="city"
                          name="city"
                          value={values.city}
                          placeholder="Your city"
                          className={`form-control ${
                            touched.city && errors.city ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>                  
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="state">
                          State <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="state"
                          name="state"
                          value={values.state}
                          placeholder="Your state"
                          className={`form-control ${
                            touched.state && errors.state ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="zip">
                          Zipcode <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="zip"
                          name="zip"
                          value={values.zip}
                          placeholder="Your zip"
                          className={`form-control ${
                            touched.zip && errors.zip ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>  
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          value={values.country}
                          placeholder="Your country of residence"
                          className={`form-control ${
                            touched.country && errors.country
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>                
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="linkedin">
                          LinkedIn URL 
                        </label>
                        <Field
                          type="linkedin"
                          name="linkedin"
                          value={values.linkedin}
                          placeholder="Your LinkedIn URL"
                          className={`form-control ${
                            touched.linkedin && errors.linkedin ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="linkedin"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <hr />

                  <MDBRow>
                  <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="mainphone">
                          Main phone <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="mainphone"
                          name="mainphone"
                          value={values.mainphone}
                          placeholder="Your main phone number"
                          className={`form-control ${
                            touched.mainphone && errors.mainphone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mainphone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>   
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="mobilephone">
                          Mobile phone 
                        </label>
                        <Field
                          type="mobilephone"
                          name="mobilephone"
                          value={values.mobilephone}
                          placeholder="Your mobile phone number"
                          className={`form-control ${
                            touched.mobilephone && errors.mobilephone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="mobilephone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>                  
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="homephone">
                          Home phone 
                        </label>
                        <Field
                          type="homephone"
                          name="homephone"
                          value={values.homephone}
                          placeholder="Your homephone number"
                          className={`form-control ${
                            touched.homephone && errors.homephone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="homephone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="workphone">
                          Work phone 
                        </label>
                        <Field
                          type="workphone"
                          name="workphone"
                          value={values.workphone}
                          placeholder="Your work phone number"
                          className={`form-control ${
                            touched.workphone && errors.workphone ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="workphone"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>   
                  </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="comments">
                          How did you hear about UNICOM Engineering careers? <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="comments"
                          value={values.comments}
                          placeholder="Please let us know how you heard about UNICOM Engineering careers"
                          className={`form-control ${
                            touched.comments && errors.comments
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="comments"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  
                  
                  <MDBRow>
                    <MDBCol md="12">
                    <div className="mb-4 font-w-600 text-medium">
                        <label htmlFor="resume">Upload resume</label>
                        <Field
                          type="file"
                          name="resume"
                          aria-label="resume"
                          onBlur={e => {
                            this.setState({
                              selectedFile: e.currentTarget.files[0],
                              loaded: 0
                            })    
                          }}
                          placeholder="Upload your resume"
                          className={`form-control ${
                            touched.resume && errors.resume ? "is-invalid" : ""
                          }`}
                        />
                        {errors.resume && <p className="is-invalid">{errors.resume}</p>}
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-3 nav-link btn-sm-block btn btn-mdb-color"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
          </MDBRow>
      </MDBContainer>
    )
  }
}

export default formResume